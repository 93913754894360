import React from 'react';
import styled from 'styled-components';

const Hero = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url('/images/hero-bg.jpg') no-repeat center center/cover;
  text-align: center;
  color: white;
  padding: 0 20px;
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const HeroSubtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const CTAButton = styled.button`
  padding: 1rem 2rem;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const openCalendlyPopup = () => {
  if (window.Calendly) {
    window.Calendly.initPopupWidget({ url: 'https://calendly.com/patchyou3/30min' });
  } else {
    console.error('Calendly is not loaded');
  }
};

const HeroSection = () => (
  <Hero id="hero">
    <HeroTitle>Transform Your Fitness Journey with Patchu</HeroTitle>
    <HeroSubtitle>Personalized training and nutrition plans to achieve your goals</HeroSubtitle>
    <CTAButton onClick={openCalendlyPopup}>Book a Free Consultation</CTAButton>
  </Hero>
);

export default HeroSection;
