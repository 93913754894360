import React from 'react';
import styled from 'styled-components';

const Instagram = styled.section`
  padding: 5rem 0;
  text-align: center;
  background-color: #ffffff;
`;

const InstagramTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const InstagramFeed = styled.div`
  display: flex;
  justify-content: center;
`;

const InstagramHandle = styled.a`
  margin: 0 1rem;
  font-size: 1.5rem;
  color: #007bff;
  text-decoration: none;
  &:hover {
    color: #0056b3;
  }
`;

const InstagramSection = () => (
  <Instagram id="instagram">
    <InstagramTitle>Follow us on Instagram</InstagramTitle>
    <InstagramFeed>
      <InstagramHandle href="https://www.instagram.com/_patch_u/" target="_blank">@_patch_u</InstagramHandle>
    </InstagramFeed>
  </Instagram>
);

export default InstagramSection;
