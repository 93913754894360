import React from 'react';
import styled from 'styled-components';

const Instructors = styled.section`
  padding: 5rem 0;
  text-align: center;
  background-color: #ffffff;
`;

const InstructorsTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const InstructorGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`;

const InstructorCard = styled.div`
  background: #f1f1f1;
  padding: 2rem;
  border-radius: 10px;
`;

const InstructorPhoto = styled.img`
  width: 25%;
  border-radius: 50%;
  margin-bottom: 1rem;
`;

const InstructorName = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const InstructorBio = styled.p`
  font-size: 1rem;
`;

const InstructorsSection = () => (
  <Instructors id="instructors">
    <InstructorsTitle>Meet your instructors</InstructorsTitle>
    <InstructorGrid>
      {[
        { name: 'Gowtham', bio: 'Expert in functional training and strength conditioning.', photo: '/images/instructor-a.jpg' },
        { name: 'Saumya', bio: 'Specialist in weight loss and Indian traditional training.', photo: '/images/instructor-b.jpg' },
        { name: 'Nithin', bio: 'Specialist in martial arts', photo: '/images/instructor-c.jpg' },

      ].map((instructor) => (
        <InstructorCard key={instructor.name}>
          <InstructorPhoto src={instructor.photo} alt={instructor.name} />
          <InstructorName>{instructor.name}</InstructorName>
          <InstructorBio>{instructor.bio}</InstructorBio>
        </InstructorCard>
      ))}
    </InstructorGrid>
  </Instructors>
);

export default InstructorsSection;
