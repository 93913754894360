import React, { useState } from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';

const Nav = styled.nav`
  background: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const NavLogo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const NavItems = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    width: 100%;
    align-items: flex-start;
  }
`;

const NavItem = styled(Link)`
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    color: #007bff;
  }

  @media (max-width: 768px) {
    margin: 0.5rem 1rem;
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
`;

const Bar = styled.div`
  height: 3px;
  width: 25px;
  background-color: white;
  margin: 4px 0;
`;

const Cross = styled.div`
  width: 25px;
  height: 25px;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: white;
    top: 50%;
    left: 0;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Nav>
      <NavLogo>Patch-U</NavLogo>
      <Hamburger onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <Cross /> : (
          <>
            <Bar />
            <Bar />
            <Bar />
          </>
        )}
      </Hamburger>
      <NavItems isOpen={isOpen}>
        <NavItem to="services" smooth={true} duration={500}>Services</NavItem>
        <NavItem to="testimonials" smooth={true} duration={500}>Testimonials</NavItem>
        <NavItem to="instructors" smooth={true} duration={500}>Instructors</NavItem>
        <NavItem to="instagram" smooth={true} duration={500}>Instagram</NavItem>
      </NavItems>
    </Nav>
  );
};

export default Navbar;
