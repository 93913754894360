import React from 'react';
import styled from 'styled-components';

const Testimonials = styled.section`
  padding: 5rem 0;
  text-align: center;
  background-color: #ffffff;
`;

const TestimonialsTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const TestimonialGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`;

const TestimonialCard = styled.div`
  background: #f1f1f1;
  padding: 2rem;
  border-radius: 10px;
`;

const ClientPhoto = styled.img`
  width: 100%;
  border-radius: 50%;
  margin-bottom: 1rem;
`;

const ClientName = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const ClientTestimonial = styled.p`
  font-size: 1rem;
  font-style: italic;
`;

const TestimonialsSection = () => (
  <Testimonials id="testimonials">
    <TestimonialsTitle>What our clients say</TestimonialsTitle>
    <TestimonialGrid>
      {[
        { name: 'Client A', testimonial: 'Patchu has transformed my fitness journey!', photo: '/images/testimonial-a.jpg' },
        { name: 'Client B', testimonial: 'The personalized training plans are amazing!', photo: '/images/testimonial-b.jpg' },
        { name: 'Client C', testimonial: 'I have seen incredible results with Patchu.', photo: '/images/testimonial-c.jpg' },
      ].map((testimonial) => (
        <TestimonialCard key={testimonial.name}>
          <ClientPhoto src={testimonial.photo} alt={testimonial.name} />
          <ClientName>{testimonial.name}</ClientName>
          <ClientTestimonial>{testimonial.testimonial}</ClientTestimonial>
        </TestimonialCard>
      ))}
    </TestimonialGrid>
  </Testimonials>
);

export default TestimonialsSection;
