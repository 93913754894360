import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600;700&display=swap');

  body {
    margin: 0;
    font-family: 'Plus Jakarta Sans', sans-serif;
    background-color: #ffffff;
  }

  h1, h2, h3 {
    font-weight: 700;
  }

  p {
    font-weight: 400;
  }

  button {
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  section:not(#hero) {
    margin: 0 20px;
  }

  @media (min-width: 768px) {
    section:not(#hero) {
      margin: 0 50px;
    }
  }
`;

export default GlobalStyle;
