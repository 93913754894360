import React from 'react';
import styled from 'styled-components';

const Services = styled.section`
  padding: 5rem 0;
  text-align: center;
  background-color: #ffffff;
`;

const ServicesTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
`;

const ServiceCard = styled.div`
  background: #ffffff;
  border: 1px solid #EAEAEA;
  border-radius: 10px;
  box-shadow: 0 -1px 20px rgba(150, 150, 150, 0.25);
  text-align: center;
  overflow: hidden;
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const ServiceName = styled.h3`
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const servicesData = [
  { name: 'Functional Training', img: 'https://source.unsplash.com/featured/?fitness' },
  { name: 'Weight Loss and Gain', img: 'https://source.unsplash.com/featured/?weightloss' },
  { name: 'Indian Traditional Training', img: 'https://source.unsplash.com/featured/?indiantraining' },
  { name: 'Strength and Conditioning', img: 'https://source.unsplash.com/featured/?strength' },
  { name: 'Muscle Building', img: 'https://source.unsplash.com/featured/?muscle' },
  { name: 'Dance Fitness', img: 'https://source.unsplash.com/featured/?dancefitness' },
  { name: 'Nutrition', img: 'https://source.unsplash.com/featured/?nutrition' },
  { name: 'Sports Training', img: 'https://source.unsplash.com/featured/?sports' },
];

const ServicesSection = () => (
  <Services id="services">
    <ServicesTitle>Our services</ServicesTitle>
    <ServicesGrid>
      {servicesData.map((service) => (
        <ServiceCard key={service.name}>
          <ServiceImage src={service.img} alt={service.name} />
          <ServiceName>{service.name}</ServiceName>
        </ServiceCard>
      ))}
    </ServicesGrid>
  </Services>
);

export default ServicesSection;
