import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './styles/GlobalStyles';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import ServicesSection from './components/ServicesSection';
import TestimonialsSection from './components/TestimonialsSection';
import InstructorsSection from './components/InstructorsSection';
import InstagramSection from './components/InstagramSection';

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Navbar />
      <HeroSection />
      <ServicesSection />
      <TestimonialsSection />
      <InstructorsSection />
      <InstagramSection />
    </Router>
  );
}

export default App;
